<template>
  <div
    class="wrapper-payment-module user-e-commerce-chcekout"
    :class="{ 'payment-form-done-bg': showOrderPlacedForm, 'text-center': paymentSuccessfull, 'text-center': showPaymentPending || paymentFailed, 'd-flex justify-content-center': awatingPaymentStatus }"
  >
    <form-wizard
      v-if="!isEmpty(storeData) && entireCart && entireCart.carts && entireCart.carts.length"
      ref="refFormWizard"
      :class="{ 'form-delivery-section': !(checkoutPaymentRef && checkoutPaymentRef.paymentInfo && checkoutPaymentRef.paymentInfo.orderType == 'DELIVERY') }"
      color="#7367F0"
      :title="null"
      :subtitle="null"
      finish-button-text="Submit"
      back-button-text="Previous"
      hide-buttons
      class="checkout-form-wizard steps-transparent checkout-forms user-ecommerce-checkout"
    >
      <!-- account detail tab -->
      <tab-content
        :title="$t('Cart')"
        icon="fa fa-shopping-cart"
      >
        <user-e-commerce-checkout-cart
          class="checkout-cart-data"
          :selected-delivery-option="selectedDeliveryOption"
          @next-step="formWizardNextStep"
          :storeData="storeData"
        >
          <template v-if="storeData.product_type != 2" v-slot:orderType>
            <b-card>
              <b-form-group
                v-if="checkoutPaymentRef"
                :label="$t('Order Type')"
                class="mb-0"
              >
                <b-form-group
                  v-for="(item, index) of orderTypes.filter(x => x.value)"
                  :key="index"
                  class="wrapper-payment mb-75"
                >
                  <b-img
                    :title="item.text"
                    class="common-width-images"
                    :src="item.icon"
                  />
                  <b-form-radio
                    v-model="checkoutPaymentRef.paymentInfo.orderType"
                    name="order-type-radio"
                    :value="item.value"
                    @change="checkoutPaymentRef.setOrderType(item.value)"
                  >
                    {{ $t(item.text) }}
                  </b-form-radio>
                </b-form-group>
                <b-form-select
                  v-model="checkoutPaymentRef.paymentInfo.orderType"
                  class="d-none"
                  :options="checkoutPaymentRef.orderType"
                  @change="checkoutPaymentRef.getPromoCodeData"
                />
              </b-form-group>
            </b-card>
          </template>
        </user-e-commerce-checkout-cart>
      </tab-content>

      <!-- address -->
      <tab-content
        v-if="storeData.product_type != 2"
        :title="$t('Address')"
        icon="fa fa-home"
      >
        <user-e-commerce-cart-address
          ref="cartAddress"
          :selected-delivery-option="selectedDeliveryOption"
          @next-step="formWizardNextStep"
          @delivery-address="deliveryAddress"
        />
      </tab-content>

      <!-- social link -->
      <tab-content
        :title="$t('Payment')"
        icon="fa fa-credit-card"
      >
        <user-e-commerce-checkout-payment
          ref="checkoutPayment"
          :selected-address="selectedAddress"
          :selected-delivery-option="selectedDeliveryOption"
          :storeData="storeData"
          @reset-delivery="resetDelivery"
          @set-delivery-option="setDeliveryOption"
          @need-delivery-option="needDeliveryOption"
          @next-step="placeOrder"
        />
      </tab-content>
    </form-wizard>
    <div v-else-if="showOrderPlacedForm">
      <order-placed-successfully
        :order-number="orderNumber"
        :sub-title="storeData.product_type == 2 ? 
        'We have successfully placed your order.'
        : 'We have successfully placed your order. Store will soon accept your order'
        "
        @go-to-order="goToOrder"
      />
      <div class="col-md-12 text-center">
        <b-button
          variant="success"
          class="mb-2 mx-1"
          :to="{
            name: 'apps-store-details',
            params: {
              store: storeData.slug
            }
          }"
        >
          {{ $t('Order Again') }}
        </b-button>
      </div>
      <div v-if="!isEmpty(digitalFiles)" class="col-md-12 text-center">
        <button
          v-for="(file, file_key) in digitalFiles"
          class="btn btn-primary mb-2 mx-1"
          @click="downloadFile(file)"
          :key="`download_file_${file_key}`"
        >
          <template v-if="file_key == 0">
            {{ $t('Download') }} File
          </template>
          <template v-else>
            {{ $t('Download') }} File {{file_key + 1}}
          </template>

        </button>
      </div>
      <div v-if="storeData.product_type == 1" class="col-md-12 text-center">
        <button
          class="btn btn-primary mb-2 mx-1"
          @click="goToOrder"
        >
          {{ $t('Check Order Status') }}
        </button>
      </div>
    </div>
    <div v-else-if="awatingPaymentStatus">
      <div class="in_progress_payment">
        <img
          class="payment_in_progress_img"
          :src="require('@/assets/images/payment/e-wallet-animate.svg')"
        >
      </div>
    </div>
    <div
      v-else-if="paymentSuccessfull"
      class="payment-successfull card justify-content-center d-flex"
    >
      <div>
        <h1 class="mt-2">
          {{ $t('Payment Successful') }}
        </h1>
        <p>
          {{ $t('We have successfully recieved your payment') }}
        </p>
      </div>
    </div>
    <div
      v-else-if="showPaymentPending"
      class="payment-successfull card"
    >
      <h1 class="mt-2">
        {{ $t('Please wait') }}
      </h1>
      <img
        class="payment_in_progress_img"
        :src="require('@/assets/images/payment/e-wallet-animate.svg')"
      >
    </div>
    <div
      v-else-if="paymentFailed"
      class="payment-successfull card"
    >
      <payment-retry
        :payment-options="paymentOptions"
        @payment-type-selected="paymentMethodChanged"
      />
      <div
        v-if="paymentOptions.card"
        :class="{ 'disable-effect': retryPaymentMethod === 'CASH' }"
        class="wrapper-payment-methods wrapper-payment-info-type-retry"
      >
        <b-form-group
          v-for="(item, index) of paymentTypeList.filter(x => x.value)"
          :key="index"
          class="wrapper-payment"
        >
          <b-form-radio
            v-model="paymentSelected"
            name="payment-type-radio"
            class="common-width-images"
            :value="item.value"
          >
            <b-img
              :title="item.text"
              class="common-width-images"
              :src="item.icon"
              :class="{ 'w-80px': item.class === 'w-80px', active: paymentSelected === item.value, 'w-75px': item.value === 'paypal' }"
            />
          </b-form-radio>
        </b-form-group>
      </div>
      <div
        v-if="paymentSelected"
        class="mt-2"
      >
        <button
          class="btn btn-primary"
          @click="beginPayment(paymentSelected)"
        >
          {{ $t('Continue Payment') }}
        </button>
      </div>
    </div>
    <div v-else>
      <no-data-available :type="'cart'" />
      <div class="col-md-12 text-center my-2">
        <b-link
          class="btn btn-primary"
          :to="{ path: '/user/dashboard' }"
        >
          {{ $t('Shop Now') }}
        </b-link>
      </div>
    </div>
    <stripe-payment
      ref="stripePaymentModule"
      :that="that"
      @payment-done="paymentDone"
      @form-cancelled="formCancelled"
      @order-placed-successfully="orderPlaced"
    />
  </div>
</template>

<script>
import { BLink, BButton, BFormGroup, BFormRadio, BImg, BFormSelect, BCard } from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ref } from '@vue/composition-api'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import eventBus from '@/libs/event-bus'
import PaymentRetry from '@/views/shared/PaymentRetry.vue'
import OrderPlacedSuccessfully from '../../shared/OrderPlacedSuccessfully.vue'
import UserECommerceCartAddress from './UserECommerceCartAddress.vue'
import UserECommerceCheckoutCart from './UserECommerceCheckoutCart.vue'
import UserECommerceCheckoutPayment from './UserECommerceCheckoutPayment.vue'
import StripePayment from '../../shared/StripePayment.vue'
import { useUserUi } from '../../useUser'
import { isEmpty } from '@/utilities'
import NoDataAvailable from '../../shared/NoDataAvailable.vue'

export default {
  components: {
    BLink,
    BButton,
    PaymentRetry,
    OrderPlacedSuccessfully,
    BFormGroup,
    BFormSelect,
    BCard,
    BImg,
    BFormRadio,
    FormWizard,
    TabContent,
    UserECommerceCartAddress,
    UserECommerceCheckoutCart,
    UserECommerceCheckoutPayment,
    StripePayment,
    NoDataAvailable,
  },
  data() {
    return {
      selectedDeliveryOption: null,
      needDeliveryOptionModel: null,
      paymentSuccessfull: false,
      awatingPaymentStatus: false,
      selectedAddress: null,
      needPayment: false,
      orderId: '',
      orderNumber: '',
      orderCreatedResponse: null,
      cartInfo: null,
      paymentPending: false,
      paymentFailed: false,
      showOrderPlacedForm: false,
      showPaymentPending: false,
      paypalPaymentData: {},
      phonePePayment: {},
      retryPaymentMethod: 'CARD',
      paymentSelected: '',
      checkoutPaymentRef: null,
      storeData: {},
      digitalFiles: []
    }
  },
  setup() {
    const refFormWizard = ref(null)
    const formWizardNextStep = () => {
      if (!(window.checkoutEcommerce.checkoutPaymentRef && window.checkoutEcommerce.checkoutPaymentRef.paymentInfo && window.checkoutEcommerce.checkoutPaymentRef.paymentInfo.orderType === 'DELIVERY')) {
        refFormWizard.value.nextTab()
        refFormWizard.value.nextTab()
      } else {
        refFormWizard.value.nextTab()
      }
      setTimeout(() => {
        window.SuperApp.helper.scrollTo('app')
      }, 250)
    }

    return {
      refFormWizard,
      formWizardNextStep,
    }
  },
  computed: {
    that() {
      return this
    },
    entireCart() {
      return this.getOrderId() ? {} : this.$store.state['app-ecommerce'].entireCart
    },
    paymentOptions() {
      if (this.orderCreatedResponse && this.orderCreatedResponse.invoice && Array.isArray(this.orderCreatedResponse.invoice.items)) {
        const items = this.orderCreatedResponse.invoice.items[0].store
        if (items && items.settings) {
          return {
            card: items.settings.card,
            cash: items.settings.cash,
          }
        }
      }
      return {}
    },
    paymentTypeList() {
      const paymentMethods = []
      if (this.orderCreatedResponse && this.orderCreatedResponse.payments) {
        const payment = this.orderCreatedResponse.payments
        if (payment.stripe) {
          paymentMethods.push({
            text: 'Stripe',
            value: 'stripe',
            icon: `${this.$apiUrl}/images/icons/stripe.svg`,
          })
        }
        if (payment.paypal) {
          paymentMethods.push({
            text: 'Paypal',
            value: 'paypal',
            icon: `${this.$apiUrl}/images/icons/paypal.svg`,
          })
        }
        if (payment.phonepe) {
          paymentMethods.push({
            text: 'PhonePe',
            value: 'phonepe',
            icon: `${this.$apiUrl}/images/icons/phonepe.svg`,
            class: 'w-80px',
          })
        }
        if (payment.razor) {
          paymentMethods.push({
            text: 'Razor Pay',
            value: 'razor',
            icon: `${this.$apiUrl}/images/icons/razorpay.svg`,
            class: 'w-80px',
          })
        }
        if (payment.flutterwave) {
          paymentMethods.push({
            text: 'Flutterwave',
            value: 'flutterwave',
            icon: require('@/assets/images/app-icons/sub-menu/Logo_FullColor.png'),
            class: 'w-80px',
          })
        }
      }
      return paymentMethods
    },
    orderTypes: {
      get(){
        let orderTypes = this.checkoutPaymentRef.orderType
        if(!isEmpty(this.storeData)){
          if(this.storeData.pickup != 1){
            orderTypes = orderTypes.filter(i => i.value != 'TAKEAWAY')
          }
          if(this.storeData.delivery != 1){
            orderTypes = orderTypes.filter(i => i.value != 'DELIVERY')
          }
        }
        return orderTypes
      }
    }
  },
  watch: {
    entireCart: {
      immediate: true,
      deep: true,
      handler() {
        this.checkoutPaymentRef = this.$refs.checkoutPayment
      },
    },
  },
  mounted: async function() {
    if (this.$route.query.token && this.$route.query.PayerID) {
      this.paymentSuccessfull = true
      this.awatingPaymentStatus = true
    }
    if(this.$route.query.payment_intent_client_secret){
      this.paymentSuccessfull = true
      this.awatingPaymentStatus = true
      this.$refs.stripePaymentModule.orderId = this.getOrderId()
      this.$refs.stripePaymentModule.checkStatus()
      return
    }
    if(this.$route.query.payment_intent_client_secret){
      this.paymentSuccessfull = true
      this.awatingPaymentStatus = true
      this.$refs.stripePaymentModule.orderId = this.getOrderId()
      this.$refs.stripePaymentModule.checkStatus()
      return
    }
    if(this.$route.query.tx_ref && this.$route.query.orderId){
      const { completeFlutterwavePayment } = useUserUi()
      const formData = new FormData;
      formData.append('admin_service', 'ORDER');
      formData.append('order_id', this.$route.query.orderId);
      Object.keys(this.$route.query).forEach(ok => {
        formData.append(ok, this.$route.query[ok]);
      })
      completeFlutterwavePayment(formData).then(r => {
        if(r.status === 200){
          this.orderPlaced()
          this.paymentDone()
          eventBus.$emit('refresh-on-going-services')
          showSuccessNotification(this, this.$t('Order placed successfully'))
          this.paymentSuccessfull = true
          this.awatingPaymentStatus = true
          window.SuperApp.actions.newOrder()
          this.$router.replace('/')
          return
        }
      })
    }
    window.checkoutEcommerce = this
    window.stripePaymentModule = this.$refs.stripePaymentModule
    hideLoader()
    const { userStoreDetail } = useUserUi()
    userStoreDetail({
      storeName: this.$route.params.storeName,
    }).then(({ data }) => {
      if (data._statusCode === 200) {
        this.storeData = data.responseData
        if(this.storeData.product_type == 2){
          this.$nextTick(() => {
            this.$refs.checkoutPayment.setOrderType('DIGITAL');
          })
        }
      }
    })
          
    this.getOrderDetailById()
    this.$nextTick(() => {
      this.checkoutPaymentRef = this.$refs.checkoutPayment
    })
    this.checkoutPaymentRef = this.$refs.checkoutPayment
  },
  methods: {
    isEmpty,
    orderPlaced() {
      this.awatingPaymentStatus = false
      this.showOrderPlacedForm = true
      this.needPayment = false
      this.getOrderDetailById()
    },
    paymentMethodChanged(payload) {
      this.paymentSelected = ''
      this.retryPaymentMethod = payload
      if (payload === 'CASH') {
        this.paymentSelected = 'cash'
      }
    },
    getOrderId() {
      return this.$route.query.orderId ? this.$route.query.orderId : window.SuperApp.helper.getParams('orderId')
    },
    getOrderDetailById() {
      if (!this.getOrderId()) {
        return
      }
      const { getOrderDetailById } = useUserUi()
      showLoader()
      getOrderDetailById(this.getOrderId())
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.orderNumber = data.responseData.store_order_invoice_id
            this.orderCreatedResponse = data.responseData
            this.digitalFiles = !isEmpty(this.orderCreatedResponse.digital_files) ? JSON.parse(this.orderCreatedResponse.digital_files) : [];
            this.orderId = this.orderCreatedResponse.id
            this.storeData = this.orderCreatedResponse.store
            if (this.orderCreatedResponse.status === 'WAITING_FOR_PAYMENT') {
              this.showPaymentFailed()
            } else {
              this.showOrderPlacedForm = true
              let sent_n_s = localStorage.getItem("sent_n_s"); 
              if(window.SuperApp.actions.isEmpty(sent_n_s)){
                sent_n_s = []
              }else{
                sent_n_s = sent_n_s.split(',')
              }
              if(!sent_n_s.some(i => i == this.orderId)){
                eventBus.$emit('store-order-checkout', {
                  orderId: this.orderId,
                  storeId: this.orderCreatedResponse.store ? this.orderCreatedResponse.store.id : null,
                  service: this.orderCreatedResponse.admin_service,
                  userInfo: `${this.orderCreatedResponse.user.first_name} ${this.orderCreatedResponse.user.last_name}`,
                  invoiceId: this.orderCreatedResponse.store_order_invoice_id,
                  orderType: this.orderCreatedResponse.order_type,
                })
                sent_n_s.push(this.orderId);
              }
              localStorage.setItem("sent_n_s", sent_n_s.join(','));
              this.needPayment = false
            }
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    formCancelled() {
      this.showPaymentFailed()
    },
    paymentDone() {
      if (this.orderId) {
        this.$router.push({
          path: `/user/store/order/${this.getOrderId()}`,
        })
      }
    },
    retryPayment(paymentMethod) {
      showLoader()
      const { retryPayment } = useUserUi()
      const formData = new FormData()
      formData.append('payment_method', paymentMethod)
      formData.append('admin_service', 'ORDER')
      formData.append('order_id', this.getOrderId())
      if (paymentMethod === 'stripe') {
        if (this.orderCreatedResponse && this.orderCreatedResponse.payments && this.orderCreatedResponse.payments.stripe_publishable_key) {
          formData.append('stripeToken', this.orderCreatedResponse.payments.stripe_publishable_key)
        }
      }
      retryPayment(formData)
        .then(({ data }) => {
          if (paymentMethod === 'razor') {
            this.orderCreatedResponse = {
              ...this.orderCreatedResponse,
              id: this.getOrderId(),
              razor: data.responseData,
            }
            this.razorPayInit()
          } else if (paymentMethod === 'paypal') {
            showSuccessNotification(this, 'Please complete the payment process')
            this.paypalPaymentData = data
            window.location.href = this.paypalPaymentData.link
          } else if (paymentMethod === 'phonepe') {
            showSuccessNotification(this, 'Please complete the payment process')
            this.phonePePayment = data.responseData
            if(this.phonePePayment?.instrumentResponse?.redirectInfo?.url){
              window.location.href = this.phonePePayment.instrumentResponse.redirectInfo.url
            }
          } else if (paymentMethod === 'stripe') {
            this.$refs.stripePaymentModule.orderId = this.getOrderId()
            setTimeout(() => {
              this.$refs.stripePaymentModule.initStripeKey(data.responseData.stripe_publishable_key)
              this.$refs.stripePaymentModule.checkStatus()
            }, 2000)
          } else if (paymentMethod === 'flutterwave') {
            if(data.responseData?.status == 'success'){
              window.location.href = data.responseData.data.link
            }
          } else if (data.statusCode === '200' && paymentMethod === 'cash') {
            showSuccessNotification(this, data.message)
            window.SuperApp.actions.newOrder()
            eventBus.$emit('refresh-on-going-services')
            this.needPayment = false
            this.showOrderPlacedForm = true
          } else if (data.message) {
            showDangerNotification(this, data.message)
          }
          if (paymentMethod !== 'stripe') {
            hideLoader()
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    beginPayment(paymentType = '') {
      if (!paymentType) {
        // eslint-disable-next-line no-param-reassign
        paymentType = this.orderCreatedResponse.invoice.payment_method
      }
      this.retryPayment(paymentType)
    },
    setDeliveryOption(payload) {
      this.selectedDeliveryOption = payload
    },
    needDeliveryOption(payload) {
      this.needDeliveryOptionModel = payload
    },
    placeOrder(paymentInfo) {
      if(this.needDeliveryOptionModel){
        if(window.SuperApp.actions.isEmpty(this.selectedDeliveryOption)){
          showDangerNotification(this, 'Please select delivery option!')
          return
        }
      }
      if (!paymentInfo) {
        return
      }
      const formData = new FormData()
      formData.append('promocode_id', paymentInfo.couponCode && paymentInfo.couponCode.id ? paymentInfo.couponCode.id : '')
      formData.append('wallet', paymentInfo.useWalletOption ? 1 : 0)
      formData.append('payment_mode', paymentInfo.paymentMode)
      formData.append('payment_method', paymentInfo.paymentMode === 'CARD' ? paymentInfo.paymentType : '')
      formData.append('user_address_id', this.selectedAddress)
      formData.append('delivery_date', '')
      formData.append('contactless_delivery', Number(paymentInfo.contactless_delivery))
      formData.append('order_type', paymentInfo.orderType)
      formData.append('card_id', paymentInfo.paymentMode === 'CARD' && paymentInfo.cardId ? paymentInfo.cardId : '')
      if (this.selectedDeliveryOption) {
        formData.append('courier_id', this.selectedDeliveryOption.courier_company_id)
      }
      showLoader()
      this.$store
        .dispatch('app-ecommerce/checkoutProducts', formData)
        .then(response => {
          if (response.data.statusCode === '200') {
            const stripeKey = this.$store.state['app-ecommerce'].entireCart.stripe_key
            this.cartInfo = window.SuperApp.actions.cloneDeep(this.$store.state['app-ecommerce'].entireCart)
            this.$store.commit('app-ecommerce/UPDATE_ENTIRE_CART_ITEMS', {})
            this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', 0)
            this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS', [])
            this.paymentPending = true
            this.orderId = response.data.responseData.id
            window.history.pushState({}, null, `${this.$route.path}?orderId=${this.orderId}`)
            this.orderNumber = response.data.responseData.store_order_invoice_id
            this.orderCreatedResponse = response.data.responseData
            this.storeData = this.orderCreatedResponse.store
            eventBus.$emit('refresh-on-going-services')
            if (response.data.responseData.invoice.payment_method === 'stripe') {
              this.showPaymentPending = true
              this.needPayment = true
              this.$refs.stripePaymentModule.orderId = response.data.responseData.id
              setTimeout(() => {
                this.$refs.stripePaymentModule.initStripeKey(stripeKey)
              }, 2000)
            } else if (response.data.responseData.invoice.payment_method === 'flutterwave') {
              const { makeFlutterwavePayment } = useUserUi()
              const params = {
                order_id: this.orderId,
                admin_service: 'ORDER',
                from_url: window.location.href,
              }
              makeFlutterwavePayment(params).then(response => {
                if(response.data.responseData?.status == 'success'){
                  window.location.href = response.data.responseData.data.link
                }else{
                  showDangerNotification(this, 'Please complete the payment')
                }
              }).catch(e => {
                showDangerNotification(this, 'Please complete the payment')
              })
            } else if (response.data.responseData.invoice.payment_method === 'razor') {
              this.showPaymentPending = true
              this.needPayment = true
              setTimeout(() => {
                this.razorPayInit()
              }, 2000)
            } else if (response.data.responseData.invoice.payment_method === 'phonepe') {
              this.showPaymentPending = true
              this.needPayment = true
              this.phonePayPayment()
            } else if (response.data.responseData.invoice.payment_method === 'paypal') {
              this.showPaymentPending = true
              this.needPayment = true
              this.paypalPayment()
            } else {
              this.needPayment = false
              this.showOrderPlacedForm = true
              showSuccessNotification(this, 'Order placed successfully')
              window.SuperApp.actions.newOrder()
            }
          } else {
            showDangerNotification(this, 'Failed to place order')
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    goToOrder() {
      this.paymentDone()
    },
    downloadFile(file) {
      window.open(`${this.$apiUrl}/${file}`,'_blank');
    },
    razorPayInit() {
      if (!this.orderCreatedResponse) return
      if (!this.orderCreatedResponse.razor) return
      window.razorPayPayload = this
      const options = this.orderCreatedResponse.razor
      options.handler = response => {
        const formData = new FormData()
        formData.append('admin_service', 'ORDER')
        formData.append('razorpay_payment_id', response.razorpay_payment_id)
        formData.append('razorpay_signature', response.razorpay_signature)
        formData.append('order_id', window.razorPayPayload.orderCreatedResponse.id)

        const { razorPayPayment } = useUserUi()

        razorPayPayment({
          data: formData,
          query: window.razorPayPayload.orderCreatedResponse.id,
        })
          .then(({ data }) => {
            if (data.statusCode === '200') {
              this.showOrderPlacedForm = true
              this.needPayment = false
              eventBus.$emit('refresh-on-going-services')
              window.SuperApp.actions.newOrder()
              showSuccessNotification(window.razorPayPayload, data.message)
            } else {
              window.razorPayPayload.showPaymentFailed()
              showDangerNotification(window.razorPayPayload, data.message)
            }
          })
          .catch(error => {
            window.razorPayPayload.showPaymentFailed()
            showErrorNotification(window.razorPayPayload, error)
          })
      }

      options.theme.image_padding = false
      options.modal = {
        escape: false,
        ondismiss: () => {
          window.razorPayPayload.showPaymentFailed()
          showDangerNotification(window.razorPayPayload, 'Please complete the payment')
        },
      }
      const razorPay = new window.Razorpay(options)
      razorPay.open()
    },
    deliveryAddress(selectedAddress) {
      this.selectedAddress = selectedAddress
    },
    resetDelivery() {
      this.selectedAddress = null
      if (this.$refs.cartAddress) {
        this.$refs.cartAddress.selectedAddress = null
      }
    },
    showPaymentFailed() {
      this.paymentFailed = true
      this.showPaymentPending = false
      this.showOrderPlacedForm = false
      this.needPayment = false
    },
    paypalPayment() {
      const { paypalPayment } = useUserUi()
      showLoader()
      paypalPayment(`${this.orderCreatedResponse.id}/${this.orderCreatedResponse.store_id}`)
        .then(({ data }) => {
          if (data && data.link) {
            // this.paymentDone()
            this.paypalPaymentData = data
            window.location.href = this.paypalPaymentData.link
            showSuccessNotification(this, data.message ? data.message : 'Please complete the payment')
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(window.razorPayPayload, error)
        })
    },
    phonePayPayment() {
      const { phonePePayment } = useUserUi()
      showLoader()
      phonePePayment({
        query: `${this.orderCreatedResponse.id}/ORDER`,
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            // this.paymentDone()
            this.phonePePayment = data.responseData
            if(this.phonePePayment?.instrumentResponse?.redirectInfo?.url){
              window.location.href = this.phonePePayment.instrumentResponse.redirectInfo.url
            }
            showSuccessNotification(this, data.message ? data.message : 'Please complete the payment')
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(window.razorPayPayload, error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>
<!-- 
<style scoped>
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css);
</style> -->

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}

.payment-successfull {
  h1 {
    color: #88b04b;
    font-weight: 900;
    font-size: 40px;
    margin-bottom: 10px;
  }
  p {
    color: #404f5e;
    font-size: 20px;
    margin: 0;
  }
  i {
    color: #9abc66;
    font-size: 100px;
    line-height: 200px;
    margin-left: -15px;
  }
  &.card {
    background: white;
    padding: 60px;
    border-radius: 4px;
    border: 0;
    box-shadow: 0 2px 3px #c8d0d8;
    display: inline-block;
    margin: 0 auto;
  }
}
</style>

<style scoped>
.wrapper-payment-methods img {
  width: 50px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}

body .w-80px {
  width: 100px;
}

body .w-75px {
  width: 75px;
}
</style>

<style>
.wrapper-payment-info-type-retry {
  display: flex;
  align-items: center;
  margin-left: 60px;
}

.user-e-commerce-chcekout .custom-radio .custom-control-label::before {
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .wrapper-payment-info-type-retry {
    display: block;
  }

  .wrapper-payment-info-type-retry .wrapper-payment > div {
    height: 30px;
  }
}

.ecommerce-application .list-view .ecommerce-card .item-img,
.ecommerce-application .list-view .ecommerce-card .item-img a,
.ecommerce-application .list-view .ecommerce-card .item-img img {
  width: 100%;
}

.ecommerce-application .list-view .ecommerce-card {
  grid-template-columns: 2fr 2fr 1fr;
}

@media only screen and (max-width: 767px) {
  .ecommerce-application .user-ecommerce-checkout .checkout-items .ecommerce-card .item-img img {
    width: 100%;
  }

  .ecommerce-application .list-view .ecommerce-card {
    grid-template-columns: 1fr;
  }

  .ecommerce-application .user-ecommerce-checkout .list-view .ecommerce-card .item-img {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ecommerce-application .user-ecommerce-checkout .list-view .ecommerce-card .item-cost {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .vue-form-wizard.user-ecommerce-checkout .wizard-navigation .wizard-nav li a .stepTitle {
    display: none;
  }

  .vue-form-wizard.user-ecommerce-checkout .wizard-navigation .wizard-nav li {
    margin-right: 1rem;
  }
}
</style>

<style lang="scss">
.form-delivery-section {
  ul.wizard-nav.wizard-nav-pills {
    li:nth-child(2) {
      display: none !important;
    }
  }
}

.checkout-cart-data {
  .common-width-images {
    width: 40px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 4px;
    padding: 2px;
    border: 1px solid #fff;
  }

  .user-ecommerce-checkout-payment .custom-radio .custom-control-label::before {
    top: 50%;
    transform: translateY(-50%);
  }

  .w-80px {
    width: 100px;
  }

  .w-75px {
    width: 75px;
  }

  .wrapper-payment > div {
    display: flex;
    align-items: center;
  }

  .wrapper-payment-info-type > div {
    display: flex;
    align-items: center;
    margin-left: 60px;
  }
  @media only screen and (max-width: 767px) {
    .wrapper-payment-info-type > div {
      display: block;
    }

    .wrapper-payment-info-type .wrapper-payment > div {
      height: 30px;
    }
    .payment_in_progress_img{
      height: 60vw;
    }
  }
}
.in_progress_payment{
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 80vh;
  .payment_in_progress_img{
    height: 55vw;
  }
  @media only screen and (max-width: 767px) {
    .payment_in_progress_img{
      height: 80vw;
    }
  }
}
</style>
